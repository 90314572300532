import { captureException } from '../errors/tracking/utils';

/**
 * Ensures the given parameter is an invariant.
 * Useful to ensure we never reach the default clause in a switch case with an
 * enum.
 *
 * Differs from `absurd` in that it defaults to a safe value and captures the exception.
 *
 * From https://github.com/gcanti/fp-ts
 */
export function safeAbsurd<A>(value: never, fallbackValue: A, exception: Error | string): A {
  captureException(typeof exception === 'string' ? new Error(exception) : exception, { extra: { value } });
  return fallbackValue;
}
